<template>
  <div class="h-full justify-center flex overflow-hidden">
    <div class="self-center w-6/12">
      <div class="flex">
        <img src="@/app/ui/assets/svg/not-found.svg" alt="not_found" />
        <div class="space-y-8 ml-8">
          <div class="text-2xl font-bold">
            Oops...<br />Halaman Tidak Ditemukan
          </div>
          <div class="text-gray-lp-600 text-12px xxl:text-14px leading-7">
            Halaman yang Anda minta tidak ditemukan. Silahkan kembali ke halaman
            awal atau hubungin kami di
            <a
              href="mailto:customer.care@lionparcel.com"
              class="underline text-red-lp-100"
            >
              customer.care@lionparcel.com
            </a>
          </div>
          <button-fill
            :onClick="goToDashboard"
            title="Kembali Ke Dashboard"
            custom="px-6"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { flags } from "@/feature-flags";
@Options({
  components: {}
})
export default class NotFound extends Vue {
  goToDashboard() {
    this.$router.replace(flags.flag_default_path_genesis.getValue());
  }
}
</script>

<style scoped></style>
